import React, { useContext, useState } from "react";
import { Stack, Main } from "@layout";
import CardList from "@components/CardList";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import Testimonial from "@widgets/Testimonial";
import NewsletterExpanded from "@widgets/NewsletterExpanded";
import Hero from "../components/Hero/Hero";
import styled from "styled-components";
import { AuthContext } from "@elegantstack/core-blog/src/context/auth";
import InfiniteScroll from "react-infinite-scroll-component";
import LoginCard from "@widgets/LoginCard";

const TOTAL_SECTIONS_PER_PAGE = 7;

const DividerMobile = styled.div`
  @media only screen and (max-width: 800px) {
    margin-top: 30px;
  }
`;

const Home = ({ data: { posts = {}, featuredPosts = {} }, ...props }) => {
  const { pageContext: { services = {} } = {} } = props;
  const { user } = useContext(AuthContext);
  const totalCategories = posts.group.length;

  let currentIndex = 0;
  let [loadMore, setLoadMore] = useState(true);
  let [currentPosts, setPosts] = useState(
    posts.group.slice(0, TOTAL_SECTIONS_PER_PAGE)
  );

  const fetchMoreData = () => {
    currentIndex += TOTAL_SECTIONS_PER_PAGE;
    if (currentIndex * 2 > totalCategories) {
      setLoadMore(false);
    }
    setPosts(
      currentPosts.concat(
        posts.group.slice(currentIndex, currentIndex + TOTAL_SECTIONS_PER_PAGE)
      )
    );
  };
  // console.log("user", user);

  return (
    <>
      <Seo title="Home" />
      <DividerMobile />
      <Stack>
        <Main>
          <Hero />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <CardList
            nodes={featuredPosts.nodes}
            isFeatured={true}
            limit={5}
            variant="vertical-cover"
            title="Featured this month 🤩"
            slider
            columns={[1, 2, 3, 3]}
            autoPlay
          />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <Testimonial />
        </Main>
      </Stack>
      <Stack>
        <Main>
          {user && (
            <InfiniteScroll
              dataLength={TOTAL_SECTIONS_PER_PAGE}
              next={fetchMoreData}
              hasMore={loadMore}
              useWindow={false}
              loader={<h4>Loading...</h4>}
            >
              <div style={{ overflow: "hidden" }}>
                {currentPosts.map(group => (
                  <>
                    <Divider />
                    <CardList
                      nodes={group.nodes}
                      limit={4}
                      variant="horizontal-md"
                      title={group.categoryName}
                      key={group.categoryName}
                      columns={[1, 2, 2, 2]}
                      withTitleLink
                    />
                  </>
                ))}
              </div>
            </InfiniteScroll>
          )}
        </Main>
      </Stack>
      <Stack>
        <Main>
          {!user && (
            <>
              <Divider />
              <LoginCard />
            </>
          )}
        </Main>
      </Stack>
    </>
  );
};

export default Home;
