import React from "react";
import { Link } from "gatsby";
import { Box, Heading, Button } from "theme-ui";
import Section from "@components/Section";

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  }
};

export default () => (
  <>
    <Section>
      <Heading variant="h1" sx={styles.heading}>
        It's time to Master Algorithms and Data Structures 🎉 🥳
      </Heading>
      <Heading variant="h3" sx={styles.running}>
        Finally, a high quality resource that is completely free! With over a
        decade of experience at top tech companies, we'll focus on algorithms
        with a fresh perspective. We tie theory to real world problems, and show
        you how algorithms are a part of our daily lives!
      </Heading>
    </Section>
    <Box variant="buttons.group" sx={styles.buttons}>
      <Button as={Link} to="/learn">
        Start now
      </Button>
      <Button variant="white" as={Link} to="/product">
        Learn More
      </Button>
    </Box>
  </>
);
