import React from "react";
import { Heading, Box } from "theme-ui";

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
};

export default () => (
  <Box sx={styles.wrapper}>
    <Heading variant="h1" sx={styles.heading}>
      Get offers from top tech companies
    </Heading>
    <Heading variant="h2" sx={styles.running}>
      You don't need to break the bank to access top interview resources. With
      offers from top tech companies, we'll show you what it takes to master
      coding interviews at the highest standard.
    </Heading>
  </Box>
);
